@import '../../../scss/variables';
@import '../../../scss/mixins';

.button {
    @include text(14px, white, 600, 20px);
    text-align: center;
    letter-spacing: 0.3px;
    border-radius: 4px;
    box-shadow: none;
    border: none;
    text-decoration: none;
    text-transform: uppercase;


    &.primary {
        background-color: var(--primary-color);
        color: white;
        border: 1px solid var(--primary-color);

        &:hover {
            cursor: pointer;
            color: white;
        }

        &:disabled {
            background: $color-disabled;
            cursor: not-allowed;
            border: 1px solid $color-disabled;
        }
    }

    &.secondary {
        background-color: white;
        border: 1px solid var(--primary-color);
        color: var(--primary-color);

        &:hover {
            cursor: pointer;
            background-color: var(--primary-color);
            color: white;
        }

        &:disabled {
            color: $color-disabled;
            border-color: $color-disabled;
            background: white;
            cursor: not-allowed;
        }
    }

    &.tab {
        background-color: transparent;
        border-radius: 0;
        padding: 0 0 4px 0;
        width: max-content;
        margin-right: 20px;
        font-weight: 400;
        z-index: 999;
        @include text(16px, $color-primary-text, 500, 24px);

        &:hover,
        &.active {
            cursor: pointer;
            color: var(--primary-color) !important;
            text-decoration-line: underline;
            text-decoration-thickness: 1.9px;
            text-decoration-color: var(--primary-color);
            text-underline-offset: 10.5px;
            svg path {
                fill: var(--primary-color) !important;
            }
        }
    }

    &.dashed {
        background-color: transparent;
        border: 1px dashed var(--primary-color);
        color: var(--primary-color);

        &:hover {
            cursor: pointer;
            background-color: var(--primary-color);
            color: white;
        }

        &:disabled {
            color: $color-disabled;
            border-color: $color-disabled;
            background: white;
            cursor: not-allowed;
        }
    }

    &.large {
        padding: 9px 41.5px;
    }

    &.block {
        // display: block;
        width: 100%;
    }


    &.medium {
        padding: 9px 34.5px;
    }

    &.small {
        padding: 7px 16px;
    }

    svg path {
        fill: var(--primary-color);
    }

    &:hover {
        svg path {
            fill: white;
        }
    }

}