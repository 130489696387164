@import '../../scss/variables.scss';
@import '../../scss/mixins.scss';


.notification-drawer {

    .ant-drawer-body {
        padding: 0px;
    }

    .ant-drawer-header,
    .notification-header {
        height: 72px;
        padding: auto 0;
        box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
        margin-bottom: 32px;
    }
}

.notification-item {
    // background-color: red;
    margin: 0px;
    padding: 16px 24px;
    border-bottom: 1px solid $color-table-bar;

    .title {
        @include text(14px, $color-primary-text, 500, 20px);
        @include spacing(0px, 0px);
    }

    .description {
        @include text(14px, $color-primary-text, 400, 20px);
        @include spacing((4px 0px), (0px 0px));
        width: 248px;
    }

    .timestamp {
        @include text(12px, $color-secondary-text, 400, 16px);
        @include spacing(0px, 0px);
        width: 56px;
    }

    .action-cta {
        @include text(14px, $color-primary, 400, 20px);
        cursor: pointer;
    }

    &.unread {
        background-color: var(--primary-color-light);
    }

    .cta-divider {
        height: 16px;
        border-left: 1px solid $color-table-bar;
        margin: 0px 8px;
    }
}