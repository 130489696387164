@import './scss/variables.scss';
@import './scss/mixins.scss';

// Upload
.ant-upload-text {
  font-size: 14px !important;
  color: #2d3748;
  font-weight: 400;
}

.ant-upload {
  padding: 0 !important;
  background-color: #fff !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: var(--primary-color-light);
}

.ant-picker-suffix {
  padding-right: 16px;
}

.ant-picker-range .ant-picker-active-bar {
  background-color: var(--primary-color);
}

.ant-upload-btn {
  padding: 4px 0 !important;
}

.ant-badge-dot {
  background-color: var(--primary-color);
}

.primary {
  .ant-badge-count {
    background-color: var(--primary-color);
  }
}

// Checkbox and radio
.ant-radio-checked .ant-radio-inner:after,
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.ant-radio-checked .ant-radio-inner,
.ant-radio:hover .ant-radio-inner,
.ant-checkbox-checked:hover,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--primary-color) !important;
}

// Menu
.ant-menu-submenu-arrow:before {
  color: var(--primary-color);
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: none;

  a:hover {
    color: var(--primary-color);
  }
}

.ant-menu-item {
  .ant-menu-title-content:hover {
    color: var(--primary-color);
  }

  a:hover {
    color: var(--primary-color);
  }
}

.ant-menu-submenu-title {
  .-title-content {
    color: black;

    :active {
      color: var(--primary-color);
    }
  }
}

// Spin
.ant-spin-dot-item {
  background-color: var(--primary-color);
}

// Notification
.ant-notification-notice {
  background-color: $notification-grey;
}

.ant-notification-notice-close-icon,
.ant-notification-notice-description,
.ant-notification-notice-message,
.ant-notification-notice-icon-success {
  color: #fff !important;
}

.endorsement_progress {
  .ant-progress-bg {
    height: 16px !important;
  }
}

// DatePicker
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: var(--primary-color);
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: var(--primary-color);
}

.ant-picker-month-btn,
.ant-picker-decade-btn,
.ant-picker-year-btn,
.ant-picker-today-btn {
  color: var(--primary-color);

  &:hover {
    color: var(--primary-color) !important;
    text-decoration: underline;
  }
}

// Pagination
.ant-pagination-item {
  a {
    color: $color-secondary-text;
  }
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-item-active,
.ant-pagination-item-link:hover,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination-item:hover {
  color: $color-primary-text;
  border-color: $color-primary-text;

  a {
    color: $color-primary-text;
  }
}

// Button
.ant-btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color) !important;

  &:hover {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
  }

  &:focus {
    background-color: var(--primary-color);
  }

  &:disabled {
    border: none !important;
  }
}

.ant-btn-default {
  &:hover {
    color: var(--primary-color);
    border-color: var(--primary-color);
  }
}

.ant-select-item-option-active,
.ant-select-item-option-selected {
  background-color: $color-secondary !important;
}

.ant-select-multiple {
  .ant-select-selection-overflow {
    min-height: 42px;
  }

  .ant-select-selector {
    padding: 0px 16px !important;
    border: none !important;
  }
}

// Collapse
.ant-collapse {
  background-color: transparent;
  border: none;
  border-radius: 0;
}

.ant-collapse-item {
  border-radius: 4px !important;
  border: 1px solid #ededed;
  margin-bottom: 24px;
}

// Typography
a.ant-typography,
.ant-typography a,
.ant-typography a:hover {
  color: var(--primary-color) !important;
}

// Toggle
.ant-switch-checked {
  background-color: var(--primary-color);
}

// Form
.ant-form-item-label > label {
  @include text(14px, $color-form-label, 400, 20px);
  height: auto;
  margin-bottom: 4px !important;
  padding: 0 !important;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-left: 4px;
  color: #ff4d4f;
  font-size: 14px;
  line-height: 16.94px;
  content: '*';
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

// Input
.ant-input,
.ant-select-selector,
.ant-picker-input,
.ant-input-number,
.ant-picker-range,
.ant-picker,
.ant-select {
  border: 1px solid $color-secondary-text;
  border-radius: 4px !important;
  font-size: 14px !important;
  box-shadow: none !important;

  &:focus,
  &:hover,
  &:focus-within {
    border-color: var(--primary-color) !important;
  }
}

.ant-input,
.ant-picker-input {
  padding: 10px 16px;
}

.ant-select-selector {
  border: none !important;
  padding: 6px 16px !important;
  height: auto !important;
}

.ant-picker-input {
  border: none;
}

.ant-select-selection-search-input {
  padding: 22px 0px !important;
  // line-height: 17px;
}

.ant-picker:hover,
.ant-picker-focused {
  border: 1px solid var(--primary-color) !important;
  box-shadow: none !important;
}

// Number Input
.ant-input-number {
  border-radius: 0 4px 4px 0 !important;
}

.ant-input-number-group-addon,
.ant-input-group-addon {
  background-color: white;
  border-radius: 4px 0 0 4px !important;
  border: 1px 0 1px 1px solid #e9ecef !important;
}

.ant-input-number-group-wrapper {
  width: 100%;
}

.ant-input-number-input {
  border: none !important;
  padding: 10px 16px !important;
  cursor: pointer;
  font-size: 14px;
  height: auto !important;
}

// Divider
.ant-divider {
  height: 100%;
  border-left: 2px solid #eaefee;
}

// Picker
.ant-picker {
  padding: 0 !important;
  width: 100%;
  border-radius: 4px;
}

// .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector{
//   padding: 0px !important;
// }

.ant-input-group-addon {
  background-color: $color-table-bar;
  @include text(14px, $color-primary-text, 400, 20px);
  border-right: none;
  border-color: $color-secondary-text;
}

.w-addon {
  .ant-input {
    border-left: none;
    border-radius: 0px 4px 4px 0px !important;
  }
}

.w-addon:hover ~ .ant-input-group-addon {
  border-color: var(--primary-color) !important;
}

.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  z-index: 0 !important;
}

.ant-alert-info {
  &.draw {
    background-color: #e9e9ea;
    border-color: #4b5054;
    color: #4b5054;
  }

  &.white {
    background-color: #fff;
    border-color: #97979c;
    color: #4b5054;
  }
}

.ant-select-item-option-active,
.ant-select-item-option-selected,
.ant-select-item-option:hover {
  background-color: var(--primary-color-light) !important;
}

.ant-input,
.ant-select-selector,
.ant-picker-input,
.ant-input-number,
.ant-picker,
.ant-select {
  &.select-batch {
    border: none;

    .ant-select-selector {
      padding: 0px !important;
    }

    .ant-select-selection-item {
      padding: 0px !important;
      font-size: 20px !important;
    }
  }
}

.steps {
  .anticon {
    svg path {
      fill: var(--primary-color) !important;
    }
  }

  .cta-disabled {
    color: $color-disabled !important;
  }
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon,
.ant-steps-item-process .ant-steps-item-icon {
  background-color: var(--primary-color) !important;
  color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: var(--primary-color) !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: var(--primary-color) !important;
  color: var(--primary-color) !important;

  .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 16px;
  }
}

.ant-picker-range {
  padding: 0px 16px !important;
}
