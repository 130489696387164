@import '../../../../scss/_variables';

.sub-title {
    margin-top: 40px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: var(--primary-color);
    text-decoration: underline solid var(--primary-color) 5px;
}

.product-card {
    // position: relative;
    padding: 25px 32px 33px 32px;
    border: 1px solid $color-grey-border;
    border-radius: 4px;
    box-sizing: border-box;
    // height: 256px;
    font-family: 'Inter';
    font-style: normal;

    .product-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.3px;
        color: $color-primary-text;
        margin-bottom: 24px;
    }

    .description {
        font-size: 14px;
        line-height: 17px;
        font-weight: 400;
        color: $color-primary-text;
        height: 96px;
        margin-bottom: 16px;

        .title {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: $color-primary-text;
        }
    }

    // .cta {
    //     position: absolute;
    //     right: 0;
    //     bottom: 0;
    //     bottom: 0px;

    // }
}

.product-container {
    .description {
        margin-top: 48px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: $color-primary-text;
    }
}

.product-benefits-form {
    margin-top: 48px;
}

.pointer {
    cursor: pointer;
}

.product-benefits {
    .table-cta {

        .table-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: #5D636E;
        }
    }
}

.benefits-table {

    .ant-table-thead>tr>th,
    .ant-table-tbody>tr>td {
        white-space: normal !important;
        padding: 16px 8px;
    }
}

.rdw-dropdown-selectedtext {
    font-family: 'Inter';
    font-style: normal;
    color: $color-primary-text !important;
}