@import '../../scss/variables.scss';
@import '../../scss/_mixins.scss';

.claims-processor-page {
    padding: 112px 32px 0px 32px;
}

.page-title {
    text-transform: capitalize;
    @include text(32px, $color-primary-text, 600, 36px);
    margin: 0px;
}

.claims-processor-header {
    position: fixed;
    height: 80px;
    min-width: 100%;
    background-color: #F5F5F5;
    z-index: 1;
    padding: 20px 56px;

    @media screen and (max-width: 1440px) {
        min-width: calc(100%);
    }

    .logo {
        padding: 0px;
        margin: 0px;
        height: 40px;
        cursor: pointer;
    }

}