.card {
  border-radius: 4px;
  padding: 16px;
  background-color: #FAFAFA;
  // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  // margin: 16px 0;

  // &:hover {
  //   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  // }

  .title{
    margin: 8px 0;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }

  .title-mb-16{
    margin: 8px 0 16px 0;
    text-align: center;
  }

  .subcard{
    width: 80px;
    text-align: center;
    padding: 24px 0;
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    cursor: pointer;

    &:hover, &.active {
      background-color: var(--primary-color);
      color: #fff;
    }
  }

  canvas {
    height: 375px !important;
  }
}
