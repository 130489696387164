@import "../../../scss/_variables.scss";
@import "../../../scss/_mixins.scss";

.analytics-box {
  padding: 24px 24px 30px 24px;
  background: #FAFAFA;
  border-radius: 4px;
  min-width: 225px;

  .analytics-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4B5054;
    margin-bottom: 6px;
  }

  .primary-value {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #4B5054;
  }

  .secondary-value {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #1A8038;
    min-height: 20px;
  }
}
