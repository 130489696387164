@import url('../../../scss/_mixins.scss');
@import url('../../../scss/_variables.scss');


.plan-collapse {
  background-color: #E9E9EA;

  .ant-collapse-item {
    border: none !important;

  .ant-collapse-content-box{
    padding: 24px;
  }
  }
}

.product-plan-selection {
  .heading{
    font: normal 500 16px/24px "Inter";
    margin: 0 0 16px 0;
  }

  .error-message {
    margin-left: 16px;
    color: #EB6262;
    font: normal 400 14px/20x "Inter";
    font-style: italic;
  }
}