@import '../../../scss/variables.scss';

.modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 0;
}

.modal-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
}

.modal-container {
    // border: 1px solid var(--primary-color);
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
    border-radius: 5px;

    text-align: center;
    padding: 20px;
    width: 100%;
    margin: 20px;

    .subtitle {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
    }

    &.no-margin {
        margin: 0;
    }
}

.modal-stats {
    font-size: 24px;
    font-weight: 500;
    color: var(--primary-color);
}

.description>ol>li {
  margin-bottom: 8px;
}

.endorsement-premium-modal {
  color: $color-primary-text;

  .ant-modal-header {
    border-bottom: 0px !important;
    padding: 40px 48px;
    padding-bottom: 0px;

    .ant-modal-title {
      font-size: 32px;
      font-weight: 600;
      line-height: 36px;
      text-align: left;
    }
  }

  .ant-form-item {
    margin-bottom: 0px;
  }

  .ant-col.ant-form-item-label {
    padding-bottom: 4px;
  }

  .modal-content {
    padding: 24px;
  }

  .detail-box {
    border-radius: 4px;
    padding: 18px;
    padding-top: 15px;
    margin-bottom: 16px;
    border: 1px solid $color-secondary-text;
    box-shadow: 0px 4px 8px 0px #4B50541A;

    .subtitle {
      font-size: 14px;
      color: $color-secondary-text;
    }

    .value {
      font-size: 24px;
      font-weight: bold;
      color: $color-primary-text;
    }
  }

  .info-box {
    border-radius: 4px;
    padding: 12px 24px;
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: $color-alert-blue;
  }

  .confirm-calculated {
    margin-bottom: 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .radio-group {
    margin-bottom: 24px;
  }

  .radio-group .ant-form-item-control-input-content {
    display: flex !important;
    gap: 16px;
    margin-bottom: 16px;
    accent-color: $color-primary;

    label {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .suggestion-box {
    display: flex;
    align-items: flex-start;
    // border: 1px solid $color-table-bar;
    // padding: 12px;
    border-radius: 4px;
    margin-bottom: 24px;

    .suggestion-icon {
      font-size: 24px;
      margin-right: 12px;
    }

    .suggestion-text {
      font-size: 14px;
      line-height: 20px;

      .text-bold {
        font-weight: 600;
      }
    }

  }

  .input-label {
    color: $color-form-label;
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 20px;
  }

  .ant-input,
  .ant-input-textarea {
    margin-bottom: 16px;
  }

  .modal-footer {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;

    .ant-btn {
      min-width: 100px;
    }
  }
}