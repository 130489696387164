@import '../../../scss/variables.scss';
@import '../../../scss/mixins.scss';

.ant-table {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
}


.ant-table-thead>tr>th,
.ant-table-tbody>tr>td {
    @include text(14px, $color-primary-text, 400, 20px);
    height: 36px;
    box-shadow: inset 0px -1px 0px #E9E9EA;
    padding: 0 8px 0 8px;
    overflow-wrap: normal;
    white-space: nowrap;
}

.ant-table-thead>tr>th {
    color: $color-primary-text;
    background-color: $color-table-bar;
    padding: 10px;
    @include text(14px, $color-primary-text, 500, 20px);
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
}

.table-cta {
    // padding: 20px 0;

    .filter {
        text-align: right;
    }

    .cta {
        flex-direction: row;
        flex: auto;
        justify-content: flex-end;
    }

    .content {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.3px;
        cursor: pointer;


        &:hover,
        &.active {
            color: $color-primary-text;
        }
    }

    svg path {
        fill: var(--primary-color);
    }

    .cta-disabled {
        color: $color-disabled;
        cursor: not-allowed;

        svg {
            cursor: not-allowed;
        }

        svg path {
            fill: $color-disabled;
        }
    }
}

.invisible-divider {
    border: none;
}

.search-input {
    background-color: white;
    padding: 8px 12px !important;
    box-sizing: border-box;
    border-radius: 4px;
    height: 36px;
    border-color: $color-secondary-text;
    cursor: pointer;

    &.universal {
        width: 400px;
    }

    &:focus,
    &:active,
    &:hover,
    &:after,
    &:focus-visible,
    &:focus-within {
        outline: none;
        border-color: var(--primary-color) !important;
        box-shadow: none !important;
    }

    &.large {
        width: 230px;
    }

    &.medium {
        width: 200px;
    }

    &.small {
        width: 180px;
    }
}

.pagination {
    background-color: $color-table-bar;
    padding: 15px 40px 15px 54px;

    .ant-pagination-item {
        @include text(12px, $color-secondary-text, 600, 16px);
        padding: 0 8px;
    }

    .ant-pagination-item-active {
        background-color: transparent;
        border: none;
    }

    .ant-pagination-options {
        float: right;
        padding-right: 24px;
    }

    .ant-pagination-options::before {
        content: "Entries per page";
        @include text(12px, $color-secondary-text, 400, 16px);
        margin-right: 8px;
    }

    .ant-select-selector {
        border: none !important;
        border-radius: 4px !important;
        padding: 0 6px !important;
        margin: 0 !important;
    }

    .ant-select {
        border: none !important;
        padding: 0 !important;
    }
}

.table-cta-icon {
    color: $color-secondary-text;
}

.table-cta-small-btn {
    // padding: 10px !important;\
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid $color-secondary-text;
    cursor: pointer;
    height: 36px;
    width: 36px;
    text-align: center;

    &.active {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
        color: white;
    }
}

.table-count-summary {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #4B5054;
}