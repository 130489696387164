@import './scss/mixins.scss';
@import './scss/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

:root {
  --primary-color: ;
  --logo-url: ;
}

body {
  height: max-content;
}

.login-layout {
  font-family: 'Inter';
  font-style: normal;
  min-height: 100vh;
  background-color: $color-border;
  // margin: 0 auto;
  position: relative;
}

.layout {
  // min-height: 100vh;

  @media screen and (max-width: 1167px) {
    display: none;
  }
}

.no-show-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  text-align: center;

  @media screen and (min-width: 1168px) {
    display: none;
  }
}

.mr-32 {
  margin-right: 32px;
}

.mr-24 {
  margin-right: 24px;
}

.container {
  margin: 0 auto;
  padding: 0 0 30px 0;
  scroll-behavior: smooth;
  font-family: 'Inter';
  font-style: normal;

  .logo {
    text-align: center;
    background-image: var(--logo-url);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    padding: 24px;
    margin: 16px;
  }
}

.pointer-on-hover {
  &:hover {
    cursor: pointer;
  }
}

.underline {
  text-decoration: underline !important;
}

.table-cta {
  margin-bottom: 24px;

  .cta {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.3px;
    // margin-right: 8px;
  }

  .cta:hover {
    cursor: pointer;
  }

  .cta-divider {
    border: 0.5px solid #e9ecef;
    height: 36px;
    margin: 0 8px;
    // font-size: 23px;
  }

  .cta-text-gap-right {
    margin-right: 8px;
  }

  .cta-text-gap-left {
    margin-left: 8px;
  }
}

.table-cta-w-tabs {
  margin-top: 40px;
}

.table-cta-wo-tabs {
  margin-top: 48px;
}

.table-cta-tabs {
  margin-top: 56px;
  border-bottom: 2px solid #c9cacd;

  &.no-margin {
    margin: 0px;
  }
}

.mt-32 {
  margin-top: 32px;
}

.mt-48 {
  margin-top: 48px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-56 {
  margin-top: 56px;
}

.mt-64 {
  margin-top: 64px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-48 {
  margin-bottom: 48px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-4 {
  margin-bottom: 4px;
}

.cta-disabled {
  cursor: not-allowed !important;
}

.cta-transparent {
  color: #5f646740 !important;
}

.icon-plus-text {
  color: var(--primary-color);
  margin: 20px 0px;
  cursor: pointer;
}

.title {
  margin: 42px 0 24px 0;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: black;
}

.subtitle {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: black;
}

.alert-feedback {
  margin: 16px 0;
  width: 500px;
}

.loader-div {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  text-align: center;
}

.loader {
  padding: 50px;
  border-radius: 4px;
}

.error-backend {
  margin-top: -24px;
}

.convert-to-capital {
  text-transform: uppercase;
}

.text-green {
  color: var(--primary-color);
}

.overflow-menu {
  background-color: white;
  border: 1px solid #c9cacd;
  box-shadow: 0px 0px 32px rgba(62, 73, 70, 0.3);
  border-radius: 4px;
  width: 208px;
  padding: 8px 0;

  .item {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #4b5054;
    padding: 8px 16px;
    width: 206px;
  }

  .item:hover {
    background-color: $color-overflow-hover;
    cursor: pointer;
  }
}

.margin-top {
  margin-top: 24px;
}

.margin-bottom {
  margin-bottom: 24px;
}

.margin-right {
  margin-right: 24px;
}

.margin-left {
  margin-left: 24px;
}

.ml-32 {
  margin-left: 32px;
}

.ml-8 {
  margin-left: 8px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

a,
a:visited,
a:hover,
a:active {
  &.clean-anchor {
    text-decoration: none;
    color: inherit !important;
    font-size: inherit !important;
  }

  &.primary {
    color: var(--primary-color) !important;
  }
}

.color-link {
  color: $color-link;
}

.incomplete-alert {
  margin-left: -16px;
  color: $color-alert-red;
}

.red-icon {
  color: $color-alert-red;
}

.color-red {
  color: $color-alert-red;
}

.actions-pending-symbol {
  color: var(--primary-color);
  font-size: 24px;
}

.form-bottom-border {
  box-shadow: inset 0px -2px 0px #e9ecef;
  padding-bottom: 24px;
}

.error {
  color: #c92a2a;
}

.success {
  color: var(--primary-color);
}

.full-width {
  width: 100% !important;
}

.pointer-on-hover:hover {
  cursor: pointer;
}

.subtext {
  font-size: 12px;
  font-weight: 400;
  color: #536262;
}

.upload-replace {
  background-color: white;
  padding: 10px 20px;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.benefit_html {
  p {
    margin-bottom: 0;
  }
}

.benefit_html,
.benefit_html * {
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-family: 'Inter' !important;
}

.download-template {
  color: var(--primary-color);

  &:hover {
    color: var(--primary-color);
  }
}

.no-margin {
  margin: 0 !important;
}

.editorClassName {
  padding-left: 8px;
  padding-bottom: 24px;
  font-size: 12px;
  font-family: 'Inter';
  max-width: 512px;
  line-height: 14.52px;
}

.wrapperClassName {
  width: 100%;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  font-family: 'Inter';
  font-style: normal;
}

.cta {
  color: var(--primary-color);
  cursor: pointer;
}

.table-filter-drawer {
  .drawer-header {
    padding: 26px 32px;
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;

    .filter-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      color: #343a40;
    }
  }

  .active-filter-tags {
    margin: 24px 30px;
  }

  .drawer-footer {
    margin-top: 24px;
    padding: 20px;
    box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }

  .ant-drawer-body {
    padding: 0 !important;
  }

  .ant-collapse-header {
    padding: 14px 32px !important;
    background-color: #e9f2f2;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #343a40 !important;
  }

  .ant-checkbox + span {
    padding: 0 12px !important;
  }

  .ant-collapse-content-box {
    padding: 16px 24px 16px 32px !important;
  }

  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    right: 32px !important;
  }
}

.edit-member-drawer {
  .ant-collapse-header {
    padding: 14px 32px !important;
    background-color: #e9f2f2;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #343a40 !important;
  }
}

.primary-text {
  color: $color-primary-text !important;
}

.important,
.discrepancy {
  color: var(--primary-color) !important;
}

.mt-24 {
  margin-top: 24px;
}

.mt-8 {
  margin-top: 8px;
}

.mb-32 {
  margin-bottom: 32px;
}

.client-details {
  margin-top: 48px;

  .key {
    font-size: 14px;
    color: $color-primary-text;
    font-weight: 600;
    line-height: 20px;
  }

  .value {
    font-size: 14px;
    color: $color-primary-text;
    font-weight: 300;
    line-height: 20px;
  }
}

.client-cta-buttons {
  margin-top: 20px;
  padding-top: 20px;
}

.alert-div {
  text-align: center;
  padding: 64px;
  background-color: #fafafa;
  margin-top: 100px;

  p {
    color: red;
  }
}

.client-cta-card {
  border: 1px solid $color-light-blue;
  box-sizing: border-box;
  border-radius: 10px;
  // z-index: 1;
  padding: 32px 57.5px;
  margin-top: 20px;

  :hover {
    cursor: pointer;
  }

  .icon-boxed {
    padding: 13px;
    border-radius: 12px;
    border: 0.5px solid var(--primary-color);
    line-height: 1;
    height: 48px;
    width: 48px;
    color: var(--primary-color);

    svg path {
      fill: var(--primary-color);
    }
  }

  .text {
    font-size: 18px;
    color: $dark-grey;
    font-weight: 700;
    line-height: 105.5%;
    margin-left: 16px;
  }
}

.drawer-form-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: var(--primary-color);
  margin-bottom: 20px;
}

.border-radius-4 {
  border-radius: 4px;
}

.add-product-table > table > tbody {
  & > tr {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #4b5054;

    :first-child {
      width: 104px;
      padding-bottom: 8px;
    }

    :nth-child(2) {
      padding-bottom: 8px;

      &::before {
        content: ':';
        margin-right: 10px;
      }
    }
  }
}

.main-page {
  margin-top: 112px;
}

a,
a:hover {
  color: var(--primary-color);
}

.indicator {
  font-size: 24px;
  color: var(--primary-color);
}

.helper-text {
  @include text(12px, $color-secondary-text, 400, 16px);
}

.required::after {
  display: inline-block;
  margin-left: 4px;
  color: #ff4d4f;
  font-size: 14px;
  line-height: 16.94px;
  content: '*';
}

.label {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #5f6467;
}

.filter-tags {
  padding: 4px 12px;
  background: #ffffff;
  border: 1px solid var(--primary-color);
  border-radius: 12px;

  .filter {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.3px;
    color: var(--primary-color);
  }

  .remove-icon {
    color: var(--primary-color);
    margin-left: 16px;
    height: 8px;
    width: 8px;
  }
}

.amount::after {
  content: '₹';
}

.fake-enabler {
  .ant-collapse-header {
    color: rgba(0, 0, 0, 0.85) !important;
    cursor: default !important;
  }
}

.no-bottom-margin {
  margin-bottom: 0px;
}

.no-bottom-margin-content {
  margin-top: 4px;
  margin-bottom: 20px !important;
}

.mt-36 {
  margin-top: 36px;
}

.pseudo-table-header {
  color: $color-primary-text;
  background-color: $color-table-bar;
  padding: 10px 0;
  @include text(14px, $color-primary-text, 500, 20px);
}

.pseudo-table-row {
  box-shadow: 0px -1px 0px 0px #e9e9ea inset;
  min-height: 56px;

  &:last-child {
    box-shadow: none;
  }
}

.last-synced {
  color: #93959b;
  font: normal 400 14px/20px 'Inter';
}

.browse-blue {
  color: $color-link;
  cursor: pointer;
}

.unmapped-field.ant-form-item {
  .ant-form-item-label::after {
    content: ' #';
    color: #e65f07;
  }

  .ant-input,
  .ant-select-selector,
  .ant-picker-input,
  .ant-input-number,
  .ant-picker-range,
  .ant-picker,
  .ant-select,
  .ant-input-number-group-addon {
    border: 1px solid #e65f07;
  }

  .ant-input-number-group-addon {
    border-right: none;
  }
}
