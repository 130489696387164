@import '../../../scss/_mixins.scss';
@import '../../../scss/_variables.scss';

.offer-card {
  border-radius: 4px;
  border: 1px solid #E9E9EA;
  background: #FFF;
  box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.05);
  padding: 24px 32px 32px 32px;

  .type {
    border-radius: 4px;
    background: #F5F5F5;
    padding: 4px 10px;
    width: max-content;
  }

  .title{
    margin: 8px 0 12px 0;
    font: normal 400 20px/28px "Inter";
    color: #4B5054;

    .product {
      color: #5F6467;
    }

    .provider {
      font-weight: 600;
    }
  }

  .items {
    color: #5F6467;
    font: normal 400 16px/24px "Inter";

    .count {
      margin-left: 8px;
      color: #227AE5;
      font-weight: 600;
      cursor: pointer;
    }
  }
}