@import '../../scss/variables.scss';
@import '../../scss/_mixins.scss';

.fheader {
    position: fixed;
    // height: 80px;
    min-width: calc(100% - 224px);
    margin-left: 224px;
    // min-width: 100%;
    background-color: #F5F5F5;
    z-index: 2;
    // margin: 0px;
    padding: 22px 56px;

    @media screen and (max-width: 1440px) {
        min-width: calc(100% - 180px);
        margin-left: 180px;
    }

    .icon-color {
        svg path {
            fill: #93959B;
        }

        &.active,
        &:hover {
            background-color: var(--primary-color);

            svg path {
                fill: #ffffff;
            }
        }
    }

    // path {
    //     fill: #93959B;

    //     &:hover {
    //         fill: #ffffff;
    //     }
    // }


    .profile {
      @include text(14px, #383E42, 400, 20px);
    }
}