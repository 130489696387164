@import '../../scss/variables.scss';
@import '../../scss/_mixins.scss';

.navbar {
  position: fixed;
  min-height: 100vh;
  min-width: 224px;
  background-color: $color-secondary;
  // z-index: 1;

  @media screen and (max-width: 1440px) {
    min-width: 180px;
  }

  .logo {
    height: 48px;
    // width: 100%;
    margin: 16px 8px 4px 8px;
    cursor: pointer;
    max-width: 224px;
  }

  .nav-footer {
    position: absolute;
    bottom: 32px;
    left: 16px;
    right: 16px;
    border-top: 1px solid #c9cacd;
    padding: 8px;
    width: calc(100% - 32px);

    .pp {
      margin-left: 10px;
      border-radius: 50%;
      padding: 1px 2px;
      color: white;
      background-color: var(--primary-color);
    }

    .username {
      @include text(14px, $color-secondary-text, 400, 20px);
    }
  }

  .navbar-item {
    @include spacing((6px 16px), (8px 10px));
    cursor: pointer;
    background-color: inherit;
    color: inherit;
    border-radius: 4px;
  }

  .nav-group-item {
    padding: 8px 10px 8px 24px;
  }

  .nav-link {
    @include text(14px, $color-primary-text, 500, 20px);

    &:hover {
      color: white;
      background-color: var(--primary-color);
      transition: all 0.3s ease-in-out;
    }

    &.active {
      color: white;
      background-color: var(--primary-color);
    }
  }
  .admin-panel-navbar {
    color: #93959b;
    font: normal 500 14px/20px 'Inter';
    letter-spacing: 2px;
    margin-left: 24px;
    margin-bottom: 32px;
  }
}

