@import '../../scss/_variables';


.login-container {
    top: 10%;
    left: 50%;
    transform: translate(-50%, 0%);
    position: absolute;
    background-color: $color-border;
    width: 100%;

    .logo {
        cursor: pointer;
        text-align: center;
        margin-bottom: 64px;
        width: auto;
        height: auto;
        max-height: 150px;
        max-width: 300px;
        // height: 48px;

        @media screen and (max-width: 768px) {
            margin-bottom: 32px;
            // height: 32px;
        }
    }

    .login-box {
        max-width: 480px;
        background-color: white;
        box-shadow: 0px 0px 32px rgba(62, 73, 70, 0.3);
        border-radius: 8px;

        @media screen and (max-width: 768px) {
            margin: 0 16px;
            max-width: 378px;
        }

        .login-content {
            padding: 48px 112px;

            @media screen and (max-width: 768px) {
                padding: 48px 48px 80px 48px;
            }
        }


        input[type=text],
        input[type=password] {
            color: black;
        }

        .ant-input {
            height: 48px;
            border: 0.5px solid $color-grey-login-input;
            border-radius: 8px;
        }

        .ant-input-affix-wrapper-borderless,
        .ant-input-affix-wrapper-borderless-disabled,
        .ant-input-affix-wrapper-borderless[disabled] {
            background-color: transparent;
            border: 0.5px solid $color-grey-login-input;
            border-radius: 8px;
            box-shadow: none;

            &.ant-input-affix-wrapper-status-error {
                border-color: $color-red-input-error;
                box-shadow: none;
            }

            .ant-input {
                border: none !important;
            }
        }

        .ant-input-affix-wrapper-borderless:hover,
        .ant-input-eye,
        .ant-input-affix-wrapper-borderless:focus,
        .ant-input-affix-wrapper-borderless-focused {
            border-color: var(--primary-color) !important;
            box-shadow: none;
        }

        .ant-input-password-icon {
            color: var(--primary-color) !important;
        }

        .ant-input-affix-wrapper {
            padding: 0px 11px;
        }




    }

}

.login-form-item:nth-child(2) {
    margin-bottom: 24px;
}

.forgot-password,
.reset-password {
    text-align: right;
    margin-top: -16px;

    .text {
        font-size: 12px;
    }
}

.login-screen-btn {
    margin-top: 24px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.02em;
    line-height: 20px;
    padding: 8px 16px;
    width: 100%;
}

.login-with-mobile {
    text-align: center;
    // margin: 8px 0 16px 0;

    .text {
        font-size: 12px;
        line-height: 15px;
        font-weight: 500;
        color: $color-black;
    }
}

.box-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: $color-primary-text;
}

.login-form {
    // margin-top: 48px;
}

.box-subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: $color-primary-text;
    margin-bottom: 16px;
}

.cta-text {
    margin-top: 10px;
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    width: 100%;
}

.cta-text:hover {
    color: var(--primary-color);
    text-decoration: underline;
}

.password-note {
    margin: 30px 0px;
    font-size: 15px;
    font-weight: 300px;
    line-height: 24px;
    content: '';

    &.password-match {
        color: #4E4F53;
    }

    &.password-mismatch,
    &.password-error {
        color: #EB503B;
    }
}

.footer {
    margin-top: 64px;
    padding-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: $color-primary-text;
    text-align: center;
}

.divider-class {
    margin: 0 12px;
}

.google-login {
    margin-top: 16px;
}

.login-with {
    padding: 8px 16px;
    background-color: $color-light-grey ;
    border: 1px solid $color-base-grey ;
    border-radius: 4px;
    cursor: pointer;
}

.login-with-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $color-primary-text;
}