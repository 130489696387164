@import '../../scss/variables.scss';
@import '../../scss/mixins.scss';

.header {
  // margin-top: 48px;

  .breadcrumb {
    padding-top: 48px;
    @include text(12px, $color-secondary-text, 400, 20px);
    text-transform: capitalize;

    a:hover {
      text-decoration: underline;
    }

    .active-element {
      margin-top: 4px;
      text-transform: capitalize;
      @include text(32px, $color-primary-text, 600, 36px);
    }
  }

}